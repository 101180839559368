var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"cols-6"},[_c('v-card',{staticClass:"fill-height",attrs:{"outlined":""}},[_c('div',{staticClass:"pa-4"},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-autocomplete',{attrs:{"label":"Branches","disabled":_vm.loading.branches,"loading":_vm.loading.branches,"items":_vm.allBranches,"deletable-chips":"","small-chips":"","clearable":"","multiple":"","outlined":""},model:{value:(_vm.temp.loadTimeBranches),callback:function ($$v) {_vm.$set(_vm.temp, "loadTimeBranches", $$v)},expression:"temp.loadTimeBranches"}}),_c('v-select',{attrs:{"label":"Projects","disabled":_vm.loading.projects,"loading":_vm.loading.projects,"items":_vm.allProjects,"menu-props":{ bottom: true, offsetY: true },"deletable-chips":"","small-chips":"","clearable":"","multiple":"","outlined":""},model:{value:(_vm.temp.loadTimeProjects),callback:function ($$v) {_vm.$set(_vm.temp, "loadTimeProjects", $$v)},expression:"temp.loadTimeProjects"}}),_c('v-select',{attrs:{"label":"Target types","disabled":_vm.loading.targetTypes,"loading":_vm.loading.targetTypes,"items":_vm.allTargetTypes,"menu-props":{ bottom: true, offsetY: true},"deletable-chips":"","small-chips":"","clearable":"","multiple":"","outlined":""},model:{value:(_vm.temp.loadTimeTargetTypes),callback:function ($$v) {_vm.$set(_vm.temp, "loadTimeTargetTypes", $$v)},expression:"temp.loadTimeTargetTypes"}}),_c('v-select',{attrs:{"label":"Devices","disabled":_vm.loading.devices,"loading":_vm.loading.devices,"items":_vm.allDevices,"menu-props":{ bottom: true, offsetY: true},"deletable-chips":"","small-chips":"","clearable":"","multiple":"","outlined":""},model:{value:(_vm.temp.loadTimeDevices),callback:function ($$v) {_vm.$set(_vm.temp, "loadTimeDevices", $$v)},expression:"temp.loadTimeDevices"}}),_c('v-menu',{attrs:{"transition":"scale-transition","min-width":"auto","close-on-content-click":false,"offset-y":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field-with-validation',_vm._g(_vm._b({attrs:{"rules":{
                 date_format: 'YYYY-MM-DD',
                 date_before: _vm.temp.loadTimeDateTo
               },"label":"From","prepend-icon":"mdi-calendar","clearable":"","outlined":"","dense":""},on:{"click:clear":function($event){_vm.temp.loadTimeDateFrom = null}},model:{value:(_vm.temp.loadTimeDateFrom),callback:function ($$v) {_vm.$set(_vm.temp, "loadTimeDateFrom", $$v)},expression:"temp.loadTimeDateFrom"}},'v-text-field-with-validation',attrs,false),on))]}}],null,true),model:{value:(_vm.dateFromMenu),callback:function ($$v) {_vm.dateFromMenu=$$v},expression:"dateFromMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},model:{value:(_vm.temp.loadTimeDateFrom),callback:function ($$v) {_vm.$set(_vm.temp, "loadTimeDateFrom", $$v)},expression:"temp.loadTimeDateFrom"}})],1),_c('v-menu',{attrs:{"transition":"scale-transition","min-width":"auto","close-on-content-click":false,"offset-y":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
               var on = ref.on;
               var attrs = ref.attrs;
return [_c('v-text-field-with-validation',_vm._g(_vm._b({attrs:{"rules":{
                     date_format: 'YYYY-MM-DD',
                     date_after: _vm.temp.loadTimeDateFrom
                   },"label":"To","prepend-icon":"mdi-calendar","clearable":"","outlined":"","dense":""},on:{"click:clear":function($event){_vm.temp.loadTimeDateTo = null}},model:{value:(_vm.temp.loadTimeDateTo),callback:function ($$v) {_vm.$set(_vm.temp, "loadTimeDateTo", $$v)},expression:"temp.loadTimeDateTo"}},'v-text-field-with-validation',attrs,false),on))]}}],null,true),model:{value:(_vm.dateToMenu),callback:function ($$v) {_vm.dateToMenu=$$v},expression:"dateToMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},model:{value:(_vm.temp.loadTimeDateTo),callback:function ($$v) {_vm.$set(_vm.temp, "loadTimeDateTo", $$v)},expression:"temp.loadTimeDateTo"}})],1),_c('v-btn',{attrs:{"disabled":_vm.loading.data || invalid,"loading":_vm.loading.data},on:{"click":_vm.updateChart}},[_vm._v(" Query ")])]}}])})],1)])],1),_c('v-col',{staticClass:"cols-6"},[_c('v-card',{staticClass:"fill-height",attrs:{"outlined":""}},[_c('line-chart',{attrs:{"chart-data":_vm.dataCollection,"milestones":_vm.milestones}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }