import { Line, mixins } from 'vue-chartjs'
import prettyBytes from 'pretty-bytes'
import 'chartjs-adapter-moment'

const { reactiveProp } = mixins

const milestonesPlugin = {
  id: 'milestones',
  renderVerticalLine: function (chart, item) {
    const context = chart.ctx
    const horizontalScale = chart.scales.x
    const verticalScale = chart.scales.y
    const horizontalOffset = horizontalScale.getPixelForValue(item.date)
    const verticalOffset = (verticalScale.bottom - verticalScale.top) / 2 + verticalScale.top

    if (horizontalOffset < horizontalScale.left || horizontalOffset > horizontalScale.right) return

    // Render vertical line
    context.beginPath()
    context.strokeStyle = '#ff000060'
    context.moveTo(horizontalOffset, verticalScale.top)
    context.lineTo(horizontalOffset, verticalScale.bottom)
    context.stroke()
    // Write label
    context.save()
    context.translate(horizontalOffset, verticalOffset)
    context.rotate(Math.PI / 2)

    context.textAlign = 'center'
    context.textBaseline = 'middle'

    context.strokeStyle = '#ffffffbb'
    context.lineWidth = 4
    context.strokeText(item.title, 0, 0)

    context.fillStyle = '#000000'
    context.fillText(item.title, 0, 0)
    context.restore()
  },
  afterDatasetsDraw: function (chart, args, options) {
    if (options.items) {
      options.items.forEach(item => this.renderVerticalLine(chart, item))
    }
  }
}

export default {
  extends: Line,
  props: ['milestones'],
  mixins: [reactiveProp],
  watch: {
    milestones: function () {
      this.$data._chart.destroy()
      this.renderChart(this.chartData, this.options)
    }
  },
  computed: {
    options () {
      return {
        maintainAspectRatio: false,
        parsing: {
          xAxisKey: 'changeset_created',
          yAxisKey: 'size'
        },
        plugins: {
          title: {
            display: true,
            text: 'Size Metrics'
          },
          tooltip: {
            callbacks: {
              title: (tooltipItems) => {
                return `${tooltipItems[0].raw.version} (${tooltipItems[0].raw.revision})`
              },
              label: (tooltipItem) => {
                return `${tooltipItem.raw.branch} / ${tooltipItem.raw.project_name} / ${tooltipItem.raw.target_type}: ${prettyBytes(tooltipItem.raw.size)} (${tooltipItem.raw.size} B)`
              },
              footer: (tooltipItems) => {
                return this.$moment(tooltipItems[0].raw.changeset_created).format('YYYY-MM-DD HH:mm:ss')
              }
            }
          },
          milestones: {
            items: this.milestones
          }
        },
        scales: {
          y: {
            ticks: {
              maxTicksLimit: 5,
              callback: prettyBytes
            }
          },
          x: {
            type: 'time',
            time: {
              isoWeekDay: true,
              unit: false
            }
          }
        }
      }
    }
  },
  mounted () {
    this.addPlugin(milestonesPlugin)
    this.renderChart(this.chartData, this.options)
    const self = this
    this.$refs.canvas.onclick = function (event) {
      const instance = self.$data._chart
      const activePoints = instance.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)
      if (activePoints.length) {
        const url = self._.get(activePoints, [0, 'element', '$context', 'raw', 'job_url'], null)
        if (url) window.open(url, '_blank').focus()
      }
    }
  }
}
