<template>
  <div>
    <v-row dense>
      <v-col class="cols-6">
        <v-card
          class="fill-height"
          outlined
        >
          <div class="pa-4">
            <!-- validated, handleSubmit, validate -->
            <ValidationObserver ref="obs" v-slot="{ invalid }">
              <v-autocomplete
                v-model="temp.buildSizeBranches"
                label="Branches"
                :disabled="loading.branches"
                :loading="loading.branches"
                :items="allBranches"
                deletable-chips
                small-chips
                clearable
                multiple
                outlined
              />
              <v-select
                v-model="temp.buildSizeProjects"
                label="Projects"
                :disabled="loading.projects"
                :loading="loading.projects"
                :items="allProjects"
                :menu-props="{ bottom: true, offsetY: true }"
                deletable-chips
                small-chips
                clearable
                multiple
                outlined
              />
              <v-select
                v-model="temp.buildSizeTargetTypes"
                label="Target types"
                :disabled="loading.targetTypes"
                :loading="loading.targetTypes"
                :items="allTargetTypes"
                :menu-props="{ bottom: true, offsetY: true }"
                deletable-chips
                small-chips
                clearable
                multiple
                outlined
              />
              <v-menu
                v-model="dateFromMenu"
                transition="scale-transition"
                min-width="auto"
                :close-on-content-click="false"
                offset-y
                top
              >
                <template #activator="{ on, attrs }">
                  <v-text-field-with-validation
                    v-model="temp.buildSizeDateFrom"
                    :rules="{
                      date_format: 'YYYY-MM-DD',
                      date_before: temp.buildSizeDateTo
                    }"
                    label="From"
                    prepend-icon="mdi-calendar"
                    clearable
                    outlined
                    dense
                    @click:clear="temp.buildSizeDateFrom = null"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="temp.buildSizeDateFrom"
                  no-title
                />
              </v-menu>
              <v-menu
                v-model="dateToMenu"
                transition="scale-transition"
                min-width="auto"
                :close-on-content-click="false"
                offset-y
                top
              >
                <template #activator="{ on, attrs }">
                  <v-text-field-with-validation
                    v-model="temp.buildSizeDateTo"
                    :rules="{
                      date_format: 'YYYY-MM-DD',
                      date_after: temp.buildSizeDateFrom
                    }"
                    label="To"
                    prepend-icon="mdi-calendar"
                    clearable
                    outlined
                    dense
                    @click:clear="temp.buildSizeDateTo = null"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="temp.buildSizeDateTo"
                  no-title
                />
              </v-menu>
              <v-btn
                :disabled="loading.data || invalid"
                :loading="loading.data"
                @click="updateChart"
              >
                Query
              </v-btn>
            </ValidationObserver>
          </div>
        </v-card>
      </v-col>
      <v-col class="cols-6">
        <v-card
          class="fill-height"
          outlined
        >
          <line-chart
            :chart-data="dataCollection"
            :milestones="milestones"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VTextFieldWithValidation from './VTextFieldWithValidation'

import LineChart from './SizeLineChart.js'

export default {
  components: {
    VTextFieldWithValidation,
    LineChart
  },
  props: {
    milestones: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      colors: [
        '#eb3b5a',
        '#2d98da',
        '#f7b731',
        '#20bf6b',
        '#a55eea',
        '#2bcbba',
        '#778ca3'
      ],
      loading: {
        data: false,
        branches: false,
        projects: false,
        targetTypes: false
      },
      allBranches: [],
      allProjects: [],
      allTargetTypes: [],
      dateFromMenu: null,
      dateToMenu: null,
      temp: {
        buildSizeBranches: null,
        buildSizeProjects: null,
        buildSizeTargetTypes: null,
        buildSizeDateFrom: null,
        buildSizeDateTo: null
      },
      menu: null,
      dataCollection: { datasets: [], milestones: [] }
    }
  },
  methods: {
    safeSplit (input) {
      return input ? input.split(',') : null
    },
    async getAllBranches () {
      this.loading.branches = true
      try {
        const { data } = await this.$api.get('/build-sizes/branches')
        this.allBranches = data.data
      } catch (error) {
        console.error(error)
      }
      this.loading.branches = false
    },
    async getAllProjects () {
      this.loading.projects = true
      try {
        const { data } = await this.$api.get('/build-sizes/projects')
        this.allProjects = data.data
      } catch (error) {
        console.error(error)
      }
      this.loading.projects = false
    },
    async getAllTargetTypes () {
      this.loading.targetTypes = true
      try {
        const { data } = await this.$api.get('/build-sizes/target_types')
        this.allTargetTypes = data.data
      } catch (error) {
        console.error(error)
      }
      this.loading.targetTypes = false
    },
    async updateChart (redirect = true) {
      this.loading.data = true
      this.$emit('queryChange', { id: 'buildSize', value: this.temp, redirect })
      try {
        const { data } = await this.$api.get(
          '/build-sizes/data',
          {
            params: {
              branches: this.temp.buildSizeBranches,
              project_names: this.temp.buildSizeProjects,
              target_types: this.temp.buildSizeTargetTypes,
              from: this.temp.buildSizeDateFrom,
              to: this.temp.buildSizeDateTo
            }
          }
        )
        this.dataCollection = {
          datasets: this._(data.data)
            .orderBy(['branch'], ['desc'])
            .groupBy(obj => `${obj.branch} / ${obj.project_name} / ${obj.target_type}`)
            .mapValues(
              (data, branchName) => ({
                label: branchName,
                data
              })
            )
            .toArray()
            .map(
              (dataset, index) => {
                const currentColor = this.colors[index % (this.colors.length - 1)]
                dataset.backgroundColor = currentColor
                dataset.borderColor = currentColor
                dataset.color = currentColor
                return dataset
              }
            )
            .value()
        }
      } catch (error) {
        console.error(error)
      }
      this.loading.data = false
    }
  },
  mounted () {
    this.getAllBranches()
    this.getAllProjects()
    this.getAllTargetTypes()
    this.temp.buildSizeBranches = this.safeSplit(this.$route.query.buildSizeBranches) || ['trunk']
    this.temp.buildSizeProjects = this.safeSplit(this.$route.query.buildSizeProjects) || ['empty-project']
    this.temp.buildSizeTargetTypes = this.safeSplit(this.$route.query.buildSizeTargetTypes) || ['exe']
    this.temp.buildSizeDateFrom = this.$route.query.buildSizeDateFrom || this.$moment().subtract(6, 'month').format('YYYY-MM-DD')
    this.temp.buildSizeDateTo = this.$route.query.buildSizeDateTo || this.$moment().format('YYYY-MM-DD')
    this.updateChart(false)
  }
}
</script>
