<template>
  <div class="pa-4">
    <v-btn
      class="mb-2"
      @click="copyUrl"
    >
      <v-icon
        class="mr-2"
      >
        mdi-share-all
      </v-icon>
      Copy URL
    </v-btn>
    <v-row>
      <v-col>
        <router-link
          v-if="isAdmin"
          class="text-h4 font-weight-bold text-decoration-none"
          to="/size-overview"
        >
          Build Sizes
        </router-link>
        <h1 v-else>
          Build Sizes
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <size-chart
          :milestones="milestones"
          @queryChange="handleQueryChange"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <router-link
          v-if="isAdmin"
          class="text-h4 font-weight-bold text-decoration-none"
          to="/time-overview"
        >
          Build Times
        </router-link>
        <h1 v-else>
          Build Times
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <time-chart
          :milestones="milestones"
          @queryChange="handleQueryChange"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <router-link
          v-if="isAdmin"
          class="text-h4 font-weight-bold text-decoration-none"
          to="/time-overview"
        >
          Load Times
        </router-link>
        <h1 v-else>
          Load Times
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <load-time-chart
          :milestones="milestones"
          @queryChange="handleQueryChange"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import SizeChart from '@/components/SizeChart'
import TimeChart from '@/components/TimeChart'
import LoadTimeChart from '@/components/LoadTimeChart'

export default {
  name: 'Browse',
  components: {
    LoadTimeChart,
    SizeChart,
    TimeChart
  },
  data: () => ({
    loading: {
      milestones: false
    },
    bookmarkObject: {},
    milestones: []
  }),
  computed: {
    ...mapState('user', ['isAdmin']),
    bookmarkUrl () {
      return `${window.location.origin}?${new URLSearchParams(this.bookmarkObject).toString()}`
    }
  },
  methods: {
    copyUrl () {
      this.$clipboard(this.bookmarkUrl)
      this.$snackbar({
        content: 'URL Copied!',
        iconClose: true,
        transition: 'slide-y-reverse-transition'
      })
    },
    safeJoin (input) {
      return Array.isArray(input) ? input.join(',') : input
    },
    async getMilestones () {
      this.loading.milestones = true
      try {
        const { data } = await this.$api.get('/milestones')
        this.milestones = data.data.map(item => ({ ...item, date: this.$moment(item.date).valueOf() }))
      } catch (error) {
        console.error(error)
      }
      this.loading.milestones = false
    },
    async handleQueryChange (payload) {
      // Get current bookmark object and pick values that are not of current id
      const oldValue = this._(this.bookmarkObject)
        .pickBy(
          (value, key) => {
            return !key.startsWith(payload.id)
          }
        )
        .value()
      // Apply new bookmark object values
      const newValue = this._(payload.value)
        .mapValues(this.safeJoin)
        .pickBy(this._.identity)
        .value()
      this.bookmarkObject = { ...oldValue, ...newValue }
      // Change current URL if necessary
      if (payload.redirect && !this._.isEqual(this.$route.query, this.bookmarkObject)) {
        this.$router.push({ query: { ...this.bookmarkObject } })
      }
    }
  },
  created () {
    this.getMilestones()
  }
}
</script>
