<template>
 <div>
   <v-row dense>
     <v-col class="cols-6">
      <v-card class="fill-height" outlined>
        <div class="pa-4">
          <ValidationObserver ref="obs" v-slot="{ invalid }">
            <v-autocomplete
              v-model="temp.loadTimeBranches"
              label="Branches"
              :disabled="loading.branches"
              :loading="loading.branches"
              :items="allBranches"
              deletable-chips
              small-chips
              clearable
              multiple
              outlined
            />
            <v-select
              v-model="temp.loadTimeProjects"
              label="Projects"
              :disabled="loading.projects"
              :loading="loading.projects"
              :items="allProjects"
              :menu-props="{ bottom: true, offsetY: true }"
              deletable-chips
              small-chips
              clearable
              multiple
              outlined
            />
            <v-select
              v-model="temp.loadTimeTargetTypes"
              label="Target types"
              :disabled="loading.targetTypes"
              :loading="loading.targetTypes"
              :items="allTargetTypes"
              :menu-props="{ bottom: true, offsetY: true}"
              deletable-chips
              small-chips
              clearable
              multiple
              outlined
            />
            <v-select
              v-model="temp.loadTimeDevices"
              label="Devices"
              :disabled="loading.devices"
              :loading="loading.devices"
              :items="allDevices"
              :menu-props="{ bottom: true, offsetY: true}"
              deletable-chips
              small-chips
              clearable
              multiple
              outlined
            />
            <v-menu
            v-model="dateFromMenu"
            transition="scale-transition"
            min-width="auto"
            :close-on-content-click="false"
            offset-y
            top
            >
              <template #activator="{ on, attrs }">
                <v-text-field-with-validation
                v-model="temp.loadTimeDateFrom"
                :rules="{
                  date_format: 'YYYY-MM-DD',
                  date_before: temp.loadTimeDateTo
                }"
                label="From"
                prepend-icon="mdi-calendar"
                clearable
                outlined
                dense
                @click:clear="temp.loadTimeDateFrom = null"
                v-bind="attrs"
                v-on="on"
                />
              </template>
              <v-date-picker
              v-model="temp.loadTimeDateFrom"
              no-title
              />
            </v-menu>
            <v-menu
              v-model="dateToMenu"
              transition="scale-transition"
              min-width="auto"
              :close-on-content-click="false"
              offset-y
              top
            >
              <template #activator="{ on, attrs }">
                <v-text-field-with-validation
                  v-model="temp.loadTimeDateTo"
                  :rules="{
                      date_format: 'YYYY-MM-DD',
                      date_after: temp.loadTimeDateFrom
                    }"
                  label="To"
                  prepend-icon="mdi-calendar"
                  clearable
                  outlined
                  dense
                  @click:clear="temp.loadTimeDateTo = null"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="temp.loadTimeDateTo"
                no-title
              />
            </v-menu>
            <v-btn
            :disabled="loading.data || invalid"
            :loading="loading.data"
            @click="updateChart"
            >
              Query
            </v-btn>
          </ValidationObserver>
        </div>
      </v-card>
     </v-col>
     <v-col class="cols-6">
       <v-card
         class="fill-height"
         outlined>
          <line-chart
          :chart-data="dataCollection"
          :milestones="milestones"
          />
       </v-card>
     </v-col>
   </v-row>
 </div>
</template>

<script>
import VTextFieldWithValidation from './VTextFieldWithValidation'

import LineChart from './LoadTimeLineChart.js'

export default {
  components: {
    VTextFieldWithValidation,
    LineChart
  },
  props: {
    milestones: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      colors: [
        '#eb3b5a',
        '#2d98da',
        '#f7b731',
        '#20bf6b',
        '#a55eea',
        '#2bcbba',
        '#778ca3'
      ],
      loading: {
        branches: false,
        projects: false,
        targetTypes: false,
        devices: false
      },
      allBranches: [],
      allProjects: [],
      allTargetTypes: [],
      allDevices: [],
      dateFromMenu: null,
      dateToMenu: null,
      temp: {
        loadTimeBranches: null,
        loadTimeProjects: null,
        loadTimeTargetTypes: null,
        loadTimeDevices: null,
        loadTimeDateFrom: null,
        loadTimeDateTo: null
      },
      dataCollection: { datasets: [], milestones: [] }
    }
  },
  methods: {
    safeSplit (input) {
      return input ? input.split(',') : null
    },
    async getAllBranches () {
      this.loading.branches = true
      try {
        const { data } = await this.$api.get('/load-times/branches')
        this.allBranches = data.data
      } catch (error) {
        console.error(error)
      }
      this.loading.branches = false
    },
    async getAllProjects () {
      this.loading.projects = true
      try {
        const { data } = await this.$api.get('/load-times/projects')
        this.allProjects = data.data
      } catch (error) {
        console.error(error)
      }
      this.loading.projects = false
    },
    async getAllTargetTypes () {
      this.loading.targetTypes = true
      try {
        const { data } = await this.$api.get('/load-times/target_types')
        this.allTargetTypes = data.data
      } catch (error) {
        console.error(error)
      }
      this.loading.targetTypes = false
    },
    async getAllDevices () {
      this.loading.devices = true
      try {
        const { data } = await this.$api.get('/load-times/devices')
        this.allDevices = data.data
      } catch (error) {
        console.error(error)
      }
      this.loading.devices = false
    },
    async updateChart (redirect = true) {
      this.loading.data = true
      this.$emit('queryChange', { id: 'loadTime', value: this.temp, redirect })
      try {
        const { data } = await this.$api.get(
          'load-times/data',
          {
            params: {
              branches: this.temp.loadTimeBranches,
              project_names: this.temp.loadTimeProjects,
              target_types: this.temp.loadTimeTargetTypes,
              devices: this.temp.loadTimeDevices,
              from: this.temp.loadTimeDateFrom,
              to: this.temp.loadTimeDateTo
            }
          }
        )
        this.dataCollection = {
          datasets: this._(data.data)
            .orderBy(['branch'], ['desc'])
            .groupBy(obj => `${obj.branch} / ${obj.project_name} / ${obj.target_type} / ${obj.device}`)
            .mapValues(
              (data, branchName) => ({
                label: branchName,
                data
              })
            )
            .toArray()
            .map(
              (dataset, index) => {
                const currentColor = this.colors[index % (this.colors.length - 1)]
                dataset.backgroundColor = currentColor
                dataset.borderColor = currentColor
                dataset.color = currentColor
                return dataset
              }
            )
            .value()
        }
      } catch (error) {
        console.error(error)
      }
      this.loading.data = false
    }
  },
  created () {
    this.getAllBranches()
    this.getAllProjects()
    this.getAllTargetTypes()
    this.getAllDevices()
    this.temp.loadTimeBranches = this.safeSplit(this.$route.query.loadTimeBranches || 'trunk')
    this.temp.loadTimeProjects = this.safeSplit(this.$route.query.loadTimeProjects || 'empty-project')
    this.temp.loadTimeTargetTypes = this.safeSplit(this.$route.query.loadTimeTargetTypes || 'StandaloneWindows64')
    this.temp.loadTimeDevices = this.safeSplit(this.$route.query.loadTimeDevices || '')
    this.temp.loadTimeDateFrom = this.$route.query.loadTimeDateFrom || this.$moment().subtract(6, 'month').format('YYYY-MM-DD')
    this.temp.loadTimeDateTo = this.$route.query.loadTimeDateTo || this.$moment().format('YYYY-MM-DD')
    this.updateChart(false)
  }
}
</script>
